import React from 'react';
import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Thomas Finlay | Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Thomas Finlay',
  subtitle: "I'm passionate about software",
  cta: 'Learn more',
};

// ABOUT DATA
const AboutPara3 = () => {
  return (
    <>
      My current experience consists of:
      <ul className="about-wrapper__info-text" style={{fontSize: "1.6rem"}}>
        <li>Mobile app development with Swift (iOS-native) and Flutter/Dart (cross-platform).</li>
        <li>Desktop application, computer vision, data analysis, machine learning, and script development with Python.</li>
        <li>Web client development with both traditional (JavaScript, HTML, CSS) and modern (TypeScript, React, NPM/Yarn, Webpack) technology stacks.</li>
        <li>Web server development with Python, Java, and .NET Core</li>
        <li>Desktop application development with Java & Swing.</li>
        <li>Experience working with various DBMSs including PostgreSQL, Oracle, and SQLite.</li>
        <li>Working with the Open Source community to diagnose and resolve bugs and issues.</li>
      </ul>
    </>
  )
}

export const aboutData = {
  img: '',
  paragraphOne: "I am a Full-Stack Software Engineer at Lightspeed (formerly Vend).",
  paragraphTwo: "I love all things software. At present I'm particularly interested in modern web development, cryptography, machine learning, and reactive programming. But I'm always excited to be exposed to new fields and technologies!",
  paragraphThree: <AboutPara3 />,
  resume: '',
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'mykindo_app.png',
    title: 'myKindo for mobile',
    info: 'Used by over 11,000 users to order school lunches and pay school donations at hundreds of schools around New Zealand.',
    info2: 'A modern, reactive, and cross-platform app developed with Flutter, replacing the native iOS app I had written in Swift.',
    url: 'https://apps.apple.com/nz/app/mykindo/id1445622107',
    repo: '',
  },
  {
    id: nanoid(),
    img: 'project.jpg',
    title: 'PDF Auto-Opener',
    info: "A WebExtension that automatically opens PDF files embedded in pages or from force-download links on the University of Canterbury's student portal in full screen, reducing the distractions on students' screens and the amount of time wasted waiting for PDFs to open.",
    info2: "This has been very popular with fellow students, with over 800 weekly users, more than 100,000 PDFs opened annually, and overwhelmingly positive feedback.",
    url: '/projects/learn_pdf',
    repo: 'https://github.com/tfinlay/LEARN-pdf-auto-opener',
  },
  {
    id: nanoid(),
    img: 'zyfgas-plots.png',
    title: "Computer Vision Research Project",
    info: "I developed a components of a system to enable real-time static hand gesture-controlled applications based on recent work in scientific literature. Using this work, I implemented a gesture-controlled kitchen timer as a proof of concept.",
    info2: "I then wrote an IEEE-style conference paper report describing and quantitatively evaluating the components, including a literature review, and received an A+ mark for this course.",
    url: "/gesture_timer/paper.pdf",
    repo: ""
  },
  {
    id: nanoid(),
    img: 'piromania_cropped.png',
    title: 'Piromania',
    info: "For this group project, I wrote a React+MobX-like module for Java+Swing that allowed us to use a Component-based architecture with automatic side-effecting in our application.",
    info2: "This allowed us to easily define our entire UI in code (maximising code reuse and separation of concerns). It also ensured that we never had to worry about propagating changes in the game state (which was primarily the territory of another member of the group) into the GUI, since it was all automatically updated as necessary.",
    url: 'https://pironamia.gitlab.io/subsystems/the-engine',
    repo: 'https://gitlab.com/pironamia/piromania',
  },
  {
    id: nanoid(),
    img: 'menu_pdf.png',
    title: 'Ezlunch menu PDF generation',
    info: 'Used to generate customer-facing menu posters for hundreds of school and suppliers across New Zealand.',
    info2: 'This was designed to be run as an automated python script on the server, and was required to produce aesthetically pleasing and readable output for a variety of products, suppliers, and schools, and menu sizes ranging from a few products to over 50.',
    url: '/kristin-menu-sample.pdf',
    repo: '',
  }
];

// CONTACT DATA
export const contactData = {
  cta: "I'd love to hear from you.",
  btn: '',
  email: 'thomas@tfinlay.io',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/thomas-finlay-75b5531aa',
    },
    {
      id: nanoid(),
      name: 'gitlab',
      url: 'https://gitlab.com/tfinlay',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tfinlay',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
